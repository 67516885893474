<template>
  <div>
    <centered-logo-simple-header class="is-hidden-desktop" />
    <div class="split-layout larger-top-gap">
      <div class="columns is-desktop is-gapless">
        <div class="column is-three-fifths-desktop is-two-fifths-widescreen">
          <password-reset-form />
        </div>
        <split-layout-right />
      </div>
    </div>
  </div>
</template>

<script>
import CenteredLogoSimpleHeader from '@/components/CenteredLogoSimpleHeader.vue'
import PasswordResetForm from '@/components/PasswordResetForm.vue'
import SplitLayoutRight from '@/components/SplitLayoutRight.vue'

export default {
  name: 'PasswordReset',
  components: {
    CenteredLogoSimpleHeader,
    PasswordResetForm,
    SplitLayoutRight
  }
}
</script>

<style lang="scss" scoped>
  @import '../scss/split_layout.scss';
</style>
