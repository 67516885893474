<template>
  <div :class="containerClasses">
    <div class="form-box">
      <transition-group :duration="500">
        <div
          v-if="success"
          key="success"
          class="layer success-layer centered justify-start"
        >
          <span class="check-icon" />
          <div class="title">
            <h1>¡Te enviamos un correo!</h1>
          </div>

          <div class="success-text">
            Revisá tu casilla de correo. Te enviamos instrucciones para que puedas generar una nueva contraseña.
          </div>
        </div>
        <div
          v-else
          key="form"
          class="layer justify-start"
        >
          <div class="title">
            <h1>Recuperá tu contraseña</h1>
          </div>
          <form
            class="form"
            novalidate
            @submit.prevent="submitPasswordRecovery"
          >
            <ValidationProvider
              v-slot="{ errors, invalid }"
              ref="email"
              rules="required|email"
            >
              <b-field
                label="E-mail"
                :type="{ 'is-danger': submitted && invalid }"
                :message="submitted ? errors : []"
              >
                <b-input
                  v-model="email"
                  type="email"
                  size="is-medium"
                  icon="envelope"
                  class="input-email"
                  placeholder="Ingresar e-mail"
                  :disabled="loading"
                />
              </b-field>
            </ValidationProvider>
            <b-notification
              v-if="networkError"
              type="is-danger"
              aria-close-label="Ocultar"
              role="alert"
            >
              Ha ocurrido un error, por favor intenta nuevamente.
            </b-notification>
            <div class="field">
              <p class="control">
                <button
                  type="submit"
                  class="button is-success"
                  :class="{ 'loading': loading, 'success': success }"
                >
                  <template v-if="loading">
                    Enviando...
                  </template>
                  <template v-else>
                    Enviar correo
                  </template>
                </button>
              </p>
            </div>
            <div class="field">
              <p class="control">
                <router-link
                  :to="{ name: 'login' }"
                  class="button is-outlined"
                >
                  Volver
                </router-link>
              </p>
            </div>
          </form>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { extend, ValidationProvider } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('email', {
  ...email,
  message: 'Ingresa un email válido'
})

extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
})

const namespace = 'PasswordReset'

const { mapState } = createNamespacedHelpers(namespace)

export default {
  name: 'PasswordResetForm',
  components: {
    ValidationProvider
  },
  data() {
    return {
      email: ''
    }
  },
  computed: {
    ...mapState({
      networkError: (state) => state.networkError,
      loading: (state) => state.loading,
      submitted: (state) => state.submitted,
      success: (state) => state.success
    }),
    containerClasses() {
      return [
        'form-container', 'simple-form',
        this.loading ? 'loading' : '',
        this.networkError ? 'error' : '',
        this.success ? 'success' : ''
      ]
    }
  },
  destroyed() {
    this.$store.commit('PasswordReset/resetState')
  },
  methods: {
    async submitPasswordRecovery() {
      if (this.loading) return

      const validateResult = await this.$refs.email.validate()

      if (!validateResult.valid) {
        this.$store.commit('PasswordReset/submitted')
        return
      }

      // Exceptions are handled internally in the store and reflect automatically on the UI
      this.$store.dispatch(
        'PasswordReset/requestPasswordResetEmail',
        { email: this.email }
      )
    }
  }
}
</script>

<style lang='scss' scoped>
  @import '../scss/simple_form.scss';

  @media (max-width: 339.98px) {
    .simple-form .form-box .title h1 {
      font-size: 25px;
    }
  }
</style>
